import { useToast } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { FaCheckCircle, FaCloudDownloadAlt, FaExclamationTriangle } from 'react-icons/fa';
import daAxiosClient from '../../services/daAxiosClient';

type DownloadParams = {
  featureId: number | string;
  hexBinDiag: number;
  maxFloors: number;
};

export const useDownloadLeadgenReport = () => {
  const toast = useToast();

  return useMutation(
    async ({ featureId, hexBinDiag, maxFloors }: DownloadParams) => {
      // Call the API. This returns a promise that resolves with the response.
      return daAxiosClient.analysis.downloadLeadgenReport(
        [Number(featureId)],
        hexBinDiag,
        maxFloors
      );
    },
    {
      // Trigger an info toast as soon as the mutation starts.
      onMutate: () => {
        toast({
          status: 'info',
          title: 'Download in progress',
          description: 'The output will be available in your download folder within 2 minutes',
          duration: 30000,
          isClosable: true,
          position: 'top',
          icon: <FaCloudDownloadAlt size={30} />,
        });
      },
      onSuccess: (response, variables) => {
        // Create and download the file
        const contentType = response.headers['content-type'] || 'application/octet-stream';
        const blob = new Blob([response.data as BlobPart], { type: contentType });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        const filename = `${variables.featureId}_leadgen_report.pptx`;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);

        toast({
          status: 'success',
          title: 'Report downloaded successfully',
          description: 'Your LeadGen report has been downloaded.',
          duration: 9000,
          isClosable: true,
          position: 'top',
          icon: <FaCheckCircle size={30} />,
        });
      },
      onError: (error: any) => {
        toast({
          status: 'error',
          title: 'Error downloading report',
          description: 'Please try again.',
          duration: 9000,
          isClosable: true,
          position: 'top',
          icon: <FaExclamationTriangle size={30} />,
        });
      },
    }
  );
};
