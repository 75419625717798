import {
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FC, useState } from 'react';
import { FaCloudDownloadAlt } from 'react-icons/fa';
import { useDownloadLeadgenReport } from './useDownloadLeadgenReport';

type LeadGenModalProps = {
  isOpen: boolean;
  onClose: () => void;
  featureId: string | number;
};

const LeadGenReportModal: FC<LeadGenModalProps> = (props) => {
  const { isOpen, onClose, featureId } = props;
  const [hexBinDiag, setHexBinDiag] = useState('');
  const [maxFloors, setMaxFloors] = useState('');
  const { mutate: downloadReport, isLoading } = useDownloadLeadgenReport();

  const handleDownloadReport = () => {
    // Trigger the download mutation. The mutation itself will live on and complete.
    downloadReport({
      featureId,
      hexBinDiag: Number(hexBinDiag),
      maxFloors: Number(maxFloors),
    });
  };

  const handleHexBinDiagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const num = Number(value);
    // Validate that the value is a number, positive, and within desired range.
    if (!isNaN(num) && num > 0 && num <= 100) {
      setHexBinDiag(value);
    } else {
      setHexBinDiag('');
    }
  };

  const handleMaxFloorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    const num = Number(value);
    if (!isNaN(num) && num > 0 && num <= 20) {
      setMaxFloors(value);
    } else {
      setMaxFloors('');
    }
  };

  const isDisabled = !!(hexBinDiag === '' || maxFloors === '' || isLoading);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      colorScheme="brand"
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>LeadGen Powerpoint Report</ModalHeader>
        <ModalCloseButton id="mno-analysis-button-close" colorScheme="black" />
        <ModalBody>
          <Heading size="sm" mb="4">
            For Building ID: {featureId}
          </Heading>
        </ModalBody>
        <ModalFooter>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            gap="4"
            width="100%"
          >
            <FormControl as={Flex} flexGrow="0" width="auto" mr="4" alignItems="center">
              <FormLabel textAlign="end" mb="6">
                Hexbin Diagonal:
              </FormLabel>
              <InputGroup>
                <Flex flexDirection="column" alignItems="center">
                  <Input
                    type="number"
                    min={1}
                    max={100}
                    value={hexBinDiag}
                    onChange={handleHexBinDiagChange}
                  />
                  <FormHelperText>1-100</FormHelperText>
                </Flex>
              </InputGroup>
            </FormControl>
            <FormControl as={Flex} flexGrow="0" width="auto" mr="4" alignItems="center">
              <FormLabel textAlign="end" mb="6">
                Max Floors:
              </FormLabel>
              <InputGroup>
                <Flex flexDirection="column" alignItems="center">
                  <Input
                    type="number"
                    min={1}
                    max={20}
                    value={maxFloors}
                    onChange={handleMaxFloorChange}
                  />
                  <FormHelperText>1-20</FormHelperText>
                </Flex>
              </InputGroup>
            </FormControl>
            <Button
              id="download-leadgen-report-button"
              size="sm"
              flexShrink="0"
              flexGrow="0"
              rightIcon={<FaCloudDownloadAlt size="30" />}
              onClick={handleDownloadReport}
              isDisabled={isDisabled}
            >
              Download LeadGen Report
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LeadGenReportModal;
