import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
  Spinner,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { booleanPointInPolygon, point, polygon } from '@turf/turf';
import { isEqual } from 'lodash';
import { FC, useEffect, useMemo } from 'react';
import { useMap } from 'react-map-gl';
import { checkHasAnyRole } from '../../helpers/AuthProvider';
import { DetailConfig } from '../../helpers/layerConfig.types';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { closeDrawer, DrawerKey, initializeDrawer } from '../../reducers/drawersSlice';
import { LayerKey } from '../../reducers/layersSlice.types';
import {
  clearMarkerLocation,
  clearQueriedFeatureIds,
  clearSelectedFeatureId,
  clearSpectrumQueryMode,
} from '../../reducers/mapSettings';
import {
  SelectedStreetViewBuildingData,
  StreetViewInitialState,
  StreetViewPosition,
  StreetViewState,
} from '../../reducers/streetViewSlice';
import daAxiosClient from '../../services/daAxiosClient';
import LeadGenReportModal from '../LeadGenReportModal/LeadGenReportModal';
import MnoAnalysisModal from '../MnoAnalysisModal/MnoAnalysisModal';
import DetailsDrawerContent from './DetailsDrawerContent';
import SpectrumQueriedDetails from './SpectrumQueriedDetails';

type DetailsDrawerProps = {
  drawerKey: DrawerKey;
  layerId: LayerKey;
  drawerConfig: DetailConfig[];
};

const analysisAllowedLayerIds: LayerKey[] = ['buildings'];

const DetailsDrawer: FC<DetailsDrawerProps> = (props) => {
  const { drawerKey, drawerConfig, layerId } = props;

  const dispatch = useAppDispatch();
  const openDrawer = useAppSelector((s) => s.drawersSlice.openDrawer);
  const isOpen = drawerKey === openDrawer;
  const selectedFeatureId = useAppSelector((s) => s.mapSettings.selectedFeatureId);
  const buildingLocationData = useAppSelector(
    (s) => s.streetViewStateSettings.streetViewBuildingLatLong
  );

  const { 'dw-main-map': map } = useMap();
  const center = map?.getCenter();

  const isAllowedMnoAnalysisButton = checkHasAnyRole(['DenseAirGB', 'DenseAirUS']);

  const isStreetViewOpen = useAppSelector((s) => s.streetViewStateSettings.streetViewLayerOn);
  const markerPosition = useAppSelector(
    (s) => s.streetViewStateSettings.streetViewCoordinates,
    isEqual
  );
  const viewport = useAppSelector((state) => state.mapSettings.viewState.viewport);
  const south = viewport[0][0];
  const west = viewport[0][1];
  const north = viewport[1][0];
  const east = viewport[1][1];
  const viewportFeature = useMemo(
    () =>
      polygon([
        [
          [south, west],
          [north, west],
          [north, east],
          [south, east],
          [south, west],
        ],
      ]),
    [north, south, east, west]
  );

  useEffect(() => {
    if (map && center) {
      dispatch(StreetViewPosition({ lat: center.lat, lng: center.lng }));
    }

    if (markerPosition) {
      const isFeatureInViewport = booleanPointInPolygon(
        point([markerPosition.lng, markerPosition.lat]),
        viewportFeature
      );

      if (isFeatureInViewport && isStreetViewOpen) {
        dispatch(StreetViewPosition({ lat: markerPosition.lat, lng: markerPosition.lng }));
      }
    }
  }, [map, dispatch, center, markerPosition, isStreetViewOpen, viewportFeature]);

  const {
    isOpen: isMnoAnalysisModalOpen,
    onOpen: onMnoAnalysisModalOpen,
    onClose: onMnoAnalysisModalClose,
  } = useDisclosure();

  const {
    isOpen: isLeadgenModalOpen,
    onOpen: onLeadgenModalOpen,
    onClose: onLeadgenModalClose,
  } = useDisclosure();

  useEffect(() => {
    if (isOpen === undefined) {
      dispatch(initializeDrawer({ drawerKey, featureProperties: null }));
    }
  }, [dispatch, drawerKey, isOpen]);

  const { isLoading: isLeadgenAvailabilityLoading, isError: isLeadgenAvailabilityError } = useQuery(
    {
      queryKey: ['leadgen-ppt', selectedFeatureId],
      queryFn: () => {
        return daAxiosClient.analysis.getLeadgenAvailability(selectedFeatureId);
      },
      retry: false,
      refetchOnWindowFocus: false,
      refetchInterval: false,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    }
  );

  return (
    <Drawer
      isOpen={!!isOpen}
      onClose={() => {
        dispatch(clearSelectedFeatureId());
      }}
      placement="bottom"
      closeOnEsc
      size="sm"
      trapFocus={false}
      id={`${drawerKey}-details-drawer`}
      key={`${drawerKey}-details-drawer`}
    >
      <DrawerContent bg="blackAlpha.600" backdropFilter="blur(2px)">
        <DrawerHeader>
          <Flex justifyContent={'space-between'} mb={'10px'}>
            <Button
              id={'layer-street-view-button'}
              ml="0"
              mr="3"
              onClick={() => {
                const lat = buildingLocationData?.[1] ? buildingLocationData?.[1] : 0;
                const lng = buildingLocationData?.[0] ? buildingLocationData?.[0] : 0;
                dispatch(StreetViewPosition({ lat: lat, lng: lng }));
                dispatch(StreetViewInitialState(true));
                dispatch(StreetViewState(true));
                dispatch(SelectedStreetViewBuildingData(null));
                dispatch(clearSelectedFeatureId());
                dispatch(closeDrawer());
              }}
            >
              Street View
            </Button>

            {selectedFeatureId &&
            isAllowedMnoAnalysisButton &&
            analysisAllowedLayerIds.includes(layerId) ? (
              <Flex>
                <Button
                  id="mno-analysis-button-open"
                  ml="3"
                  mr="3"
                  onClick={onMnoAnalysisModalOpen}
                >
                  MNO Matrix
                </Button>
                <MnoAnalysisModal
                  isOpen={isMnoAnalysisModalOpen}
                  onClose={onMnoAnalysisModalClose}
                  featureId={selectedFeatureId}
                />
              </Flex>
            ) : null}

            {isLeadgenAvailabilityLoading ? (
              <Button id="generate-leadgen-ppt-button" ml="3" mr="auto" isDisabled={true}>
                <Text mr="2">Getting Leadgen Report</Text>
                <Spinner />
              </Button>
            ) : selectedFeatureId ? (
              <Flex>
                {isLeadgenAvailabilityError ? (
                  <Button
                    id="generate-leadgen-ppt-button-disabled"
                    ml="3"
                    mr="auto"
                    isDisabled={true}
                  >
                    Leadgen Report Not Available
                  </Button>
                ) : (
                  <Flex>
                    <Button
                      id="generate-leadgen-ppt-button-enabled"
                      ml="3"
                      mr="auto"
                      onClick={onLeadgenModalOpen}
                      isDisabled={false}
                    >
                      Leadgen Report
                    </Button>
                    <LeadGenReportModal
                      isOpen={isLeadgenModalOpen}
                      onClose={onLeadgenModalClose}
                      featureId={selectedFeatureId}
                    />
                  </Flex>
                )}
              </Flex>
            ) : null}

            <Button
              id={`${drawerKey}-details-drawer-close-button`}
              ml="auto"
              mr="0"
              _hover={{ backgroundColor: 'brand.600' }}
              bgColor="brand.500"
              onClick={() => {
                dispatch(clearSelectedFeatureId());
                dispatch(clearMarkerLocation());
                dispatch(clearSpectrumQueryMode());
                dispatch(clearQueriedFeatureIds());
                dispatch(closeDrawer());
              }}
            >
              <CloseIcon />
            </Button>
          </Flex>
        </DrawerHeader>
        <DrawerBody paddingLeft="1" paddingRight="1" paddingTop="12">
          {selectedFeatureId === null && layerId === 'spectrumLicensing' ? (
            <Box p={'20px 40px'}>
              <SpectrumQueriedDetails drawerKey={drawerKey} layerId={layerId} />
            </Box>
          ) : (
            <DetailsDrawerContent
              layerId={layerId}
              drawerConfig={drawerConfig}
              drawerKey={drawerKey}
            />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default DetailsDrawer;
