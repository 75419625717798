import { QueryObserverOptions, useQueries } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import { getBBox2d } from '../helpers/MapHelpers';
import { useLayerSettingsSelector } from '../hooks/useLayersSettings';
import {
  Buildings3dResponse,
  GetBuilding3dOptions,
  GetBuildings3dParams,
  RawBuildings3dResponse,
  useBuildings3dOptions,
} from './buildings3dClient.types';
import dwAxiosClient from './dwAxiosClient';

const getBuildings3dByBox = async (
  params: GetBuildings3dParams,
  options: GetBuilding3dOptions
): Promise<Buildings3dResponse> => {
  const {
    west,
    south,
    east,
    north,
    countryCode,
    providerName,
    includeLeadgenResults,
    leadgenType,
  } = params;

  const { signal } = options;

  if (countryCode === null || providerName === null) {
    return [];
  }

  const { data, request } = await dwAxiosClient.get<RawBuildings3dResponse>(
    `/ResultBuilding/Z/Box/${west}/${south}/${east}/${north}`,
    {
      params: { countryCode, providerName, includeLeadgenResults, leadgenType },
      signal,
      headers: {
        Accept: 'application/geo+json',
      },
    }
  );

  if (data && request.status === 200) {
    return data.features.filter((f) => f !== null);
  }

  return [];
};

const useBuildings3dQueryClient = (options: useBuildings3dOptions) => {
  const { layerKey, providerName, countryCode, enabled } = options;

  const leadgenType = useLayerSettingsSelector(layerKey, 'leadgenType', 2);
  const includeLeadgen = useLayerSettingsSelector(layerKey, 'includeLeadgen', true);

  const chunks = useLayerSettingsSelector(layerKey, 'visibleChunks', [], isEqual);

  const queries = chunks
    ? chunks.map((chunk) => {
        const chunk2d = chunk ? getBBox2d(chunk) : [];
        const [west, south, east, north] = chunk2d;

        const query: QueryObserverOptions<Buildings3dResponse | null> = {
          queryKey: [
            'buildings3d',
            countryCode,
            providerName,
            chunk2d.join('|'),
            includeLeadgen,
            leadgenType,
          ],
          queryFn: ({ signal }: any) => {
            return getBuildings3dByBox(
              {
                west: west,
                south: south,
                east: east,
                north: north,
                countryCode,
                providerName,
                includeLeadgenResults: includeLeadgen,
                ...(includeLeadgen && { leadgenType }),
              },
              { signal }
            );
          },
          staleTime: 1000 * 60 * 60 * 5,
          enabled,
        };

        return query;
      })
    : [];

  return useQueries({ queries });
};

export { useBuildings3dQueryClient };
